import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
//import { DataService } from './data.service';
import { plainToClass, classToPlain } from 'class-transformer';
import { User } from '../model/user';
import { Router } from '@angular/router';

@Injectable()
export class FirestoreService {
  errorMessage: string;
  successMessage: string;
  user: User;
  /**
   *Creates an instance of FirestoreService.
   * @param {AngularFireAuth} afAuth
   * @param {AngularFirestore} afs
   * @param {Router} router
   * @memberof FirestoreService
   */
  constructor(public afAuth: AngularFireAuth, public afs: AngularFirestore, private router: Router)
  {

  }
  /**
   *
   *
   * @param {*} value
   * @returns {Promise<any>}
   * @memberof FirestoreService
   */
  doGoogleLogin(value) : Promise<any> {
    const self = this;
    return new Promise<any>((resolve, reject) => {
      return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then( function() {
        let provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');
        console.log('afAuth >>',self);
        return self.afAuth //firebase.auth.Auth.Persistence.LOCAL
              .signInWithEmailAndPassword(value.email, value.password)//.signInWithPopup(provider)
              .then(res => {
                  console.log('do login ->',res);
                  resolve(res);
                });
      });
    })
  }
  /**
   *
   *
   * @param {*} res
   * @memberof FirestoreService
   */
  setOrUpdateUser(res)  {

    console.log('do google login ->',res.user, res.user.uid);
          const uid = res.user.uid;
          const user = res.user;
          const users = this.afs.collection('/users', ref => ref.where('uid','==',uid));
          //this.router.navigateByUrl('/user');
          users.doc(uid).get().toPromise()
          .then( _value => {
            const _valuedata = _value.data();
            console.log('_val = ', _valuedata, _value);

            if (_valuedata ) {
              //console.log('we know you');

              this.user = plainToClass(User, _valuedata);
              console.log('this.user = ', this.user);
              this.user.lastLogin = firebase.firestore.Timestamp.now();
              // because the email can change
              this.user.email = user.email;
              this.user.emailVerified = user.emailVerified;
              localStorage.setItem('user', JSON.stringify(this.user));

              users.doc(uid)
                .update(classToPlain(this.user))
                .then(_res => {
                  console.log('SUCCESS we do know you', _res);
                  this.router.navigateByUrl('/user');
                 } )
                .catch(err => console.log(err, 'FAIL You do not have access!'));
            } else {
              this.user = new User();
              this.user.uid = uid;
              this.user.email = user.email;
              this.user.emailVerified = user.emailVerified;
              this.user.firstLogin = firebase.firestore.Timestamp.now();
              this.user.lastLogin = this.user.firstLogin;
              users.doc(uid)
                .set(classToPlain(this.user))
                .then(_ => {
                  localStorage.setItem('user', JSON.stringify(this.user));
                  console.log('SUCCESS for access');
                  this.router.navigateByUrl('/user');
                 } )
                .catch(err => console.log(err, 'FAIL new. You do not have access!'));
            }
          })
          .catch( err => console.log('error = ',err));
  }
  /**
   *
   *
   * @param {*} value
   * @memberof FirestoreService
   */
  tryRegister(value) {
    if (value.password == value.password2) {
    this.doRegister(value)
    .then(res => {
      console.log(res);
      this.sendVerificationEmail();
      this.errorMessage = "";
      this.successMessage = "Your account has been created";
      // no auto login
      /*this.doGoogleLogin(value).then( res => {
        this.setOrUpdateUser(res);
      });*/
    }, err => {
      //console.log(err);
      this.errorMessage = err.message;
      this.successMessage = "";
    });
    } else {
      this.errorMessage = 'Your passwords do not match. Please try again.';
    }
  }
  /**
   *
   *
   * @param {*} value
   * @returns {Promise<any>}
   * @memberof FirestoreService
   */
  doRegister(value) : Promise<any>{
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
      .then(res => {
        resolve(res);
      }, err => reject(err))
    });
  }
  /**
   * Send email verfificaiton when new user sign up
   *
   * @returns
   * @memberof FirestoreService
   */
  sendVerificationEmail() {
    return this.afAuth.currentUser.then( user => {
      user.sendEmailVerification().then(() => {
        console.log('verification');
        this.router.navigate(['/verification']);
      });
    });
  }
  /**
   * Reset Forggot password
   *
   * @param {*} value
   * @returns
   * @memberof FirestoreService
   */
  resetPassword(value) {
    return this.afAuth.sendPasswordResetEmail(value.email)
    .then(() => {
      window.alert('Password reset email sent, check your inbox.');
    }).catch((error) => {
      window.alert(error)
    })
  }
  /**
   *
   *
   * @returns {Promise<any>}
   * @memberof FirestoreService
   */
  doLogout() : Promise<any> {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signOut().then( res => {
        resolve(res);
        localStorage.setItem('user','');
        this.user = null;
        this.router.navigateByUrl('/');
      }, err => reject(err))
    });
  }
  /**
   *
   *
   * @param {User} user
   * @memberof FirestoreService
   */
  doUpdateUser(user: User) {
    console.log('doUpdateUser ->', user);
    localStorage.setItem('user',JSON.stringify(user));
    const users = this.afs.collection('/users', ref => ref.where('uid','==',user.uid))
    users.doc(user.uid)
    .update(classToPlain(user))
    .then(_res => {
      console.log('SUCCESS updated you', _res);

     } )
    .catch(err => console.log(err, 'FAIL to update you'));
  }

  /**
   * Returns true when user is looged in and email is verified
   *
   * @readonly
   * @type {boolean}
   * @memberof FirestoreService
   */
  get isLoggedIn(): boolean {
    const ustr = localStorage.getItem('user');
    //console.log('isLoggedIn ->', ustr);
    if (ustr == '' || !ustr || ustr == null) return false;
    const user = JSON.parse(ustr);
    this.user = plainToClass(User, user);
    //console.log('isLoggedIn ->',user, this.user, (user !== null && user.emailVerified !== false));
    return (user !== null && user.emailVerified !== false) ? true : false;
    //return (user) ? true : false;
  }
}
