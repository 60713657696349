<mat-card>
    <mat-card-header i18n>Login</mat-card-header>
    <mat-card-content>
        <form [formGroup]="loginForm">
            <p>
                <mat-label i18n>Email address</mat-label>
                <input matInput type="email" formControlName="email" class="mat-input-element" required>
            </p>
            <p>
                <mat-label i18n>Password</mat-label>
                <input matInput [type]="hide ? 'password':'text'" class="mat-input-element" formControlName="password" required>
                <mat-icon style="position: relative; top: -25px; left: calc(100% - 30px);" (click)="hide = !hide" [svgIcon]="hide ? 'eye-off' : 'eye-outline'"></mat-icon>
            </p>
            <p>
                <label class="error">{{fservice.errorMessage}}</label>
                <label class="success">{{fservice.successMessage}}</label>
            </p>
            <p style="display: flex; justify-content: space-between; flex-direction: row;">
                <span [routerLink]="['/password']" style="cursor: pointer" i18n>Forgot password?</span>
                <span [routerLink]="['/register']" style="cursor: pointer" i18n>No account, please register.</span>
            </p>
        </form>


    </mat-card-content>
    <mat-card-actions>
        <button class="mat-raised-button mat-primary" (click)="loginWithGoogle(loginForm.value)" i18n>login</button>
    </mat-card-actions>
</mat-card>
