<mat-card>
    <mat-card-header>upload {{mode?mode:'xr-image'}}<span *ngIf="!fservice.user" i18n>You are not logged in. Upload would not work.</span></mat-card-header>
    <mat-card-content>
        <div class="dropzone" dropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)" [class.hovering]="isHovering">


            <h3 i18n>AngularFire Drop Zone</h3>
            <p i18n>Drag and Drop a File</p>
            <input id="fileItem" type="file" class="mat-raised-button" (change)="onFileSelect($event)">
        </div>

        <h3 i18n>Uploads</h3>

        <div *ngFor="let file of files">
            <upload-task [mode]="mode" [file]="file"></upload-task>
        </div>
    </mat-card-content>
</mat-card>
