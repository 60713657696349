<mat-card>
    <mat-card-content>
        <div style="overflow-y: auto; overflow-x: hidden; width: 100%; margin: 0.5vh 0; height: calc(48vh - 150px);">
            Here comes a description text, while the src of the iframe and the links are dynamic, too.
            <br><br> This should contain an explenation of the artwork/s, maybe some images and the authors metadata.
        </div>
        <iframe [src]="domsanitizer.bypassSecurityTrustResourceUrl('https://v-r.gallery/gallery_mode.html')" style="overflow:hidden; margin: 0 -16px; width: calc(100% + 32px); height: 48vh;" scrolling="no" frameborder="0"></iframe>

        <p style="display: flex; justify-content: space-between; flex-direction: row;">
            <a href="https://v-r.gallery/gallery_AR.html" target="blank">AR</a> <a href="https://v-r.gallery/gallery_VR.html" target="blank">VR</a>
        </p>
    </mat-card-content>
</mat-card>