<mat-card>
    <mat-card-header i18n>Forgot Password</mat-card-header>
    <mat-card-content>
        <form [formGroup]="passwordForm">
            <p>
                <mat-label i18n>Email address</mat-label>
                <input matInput type="email" formControlName="email" class="mat-input-element" placeholder="your email address" required>
            </p>
            <p>
                <label class="error">{{fservice.errorMessage}}</label>
                <label class="success">{{fservice.successMessage}}</label>
            </p>
            <p style="display: flex; justify-content: space-between; flex-direction: row;">
                <span [routerLink]="['/login']" style="cursor: pointer" i18n>Back to login</span>
                <span [routerLink]="['/register']" style="cursor: pointer" i18n>No account, please register.</span>
            </p>
            <p i18n>We use firebase for the sign in. You will receive an email from noreply@v-r-gallery.firebaseapp.com.</p>
        </form>


    </mat-card-content>
    <mat-card-actions>
        <button class="mat-raised-button mat-primary" (click)="resetPassword(passwordForm.value)" i18n>Reset password</button>
    </mat-card-actions>
</mat-card>
