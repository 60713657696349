<p (click)="closeMenu('home')">
    <mat-icon svgIcon="home-outline" style="width: 24px; height: 24px;"></mat-icon>
    <span style="display: inline-block; vertical-align: top; height: 24px; margin: 3px 10px;" i18n>home</span>
</p>
<mat-divider></mat-divider>
<p (click)="closeMenu('exhibition')" style="vertical-align: middle;">
    <mat-icon svgIcon="image-outline" style="width: 24px; height: 24px;"></mat-icon>
    <span style="display: inline-block; vertical-align: top; height: 24px; margin: 3px 10px;" i18n>current exhibition</span>
</p>
<mat-divider></mat-divider>
<p (click)="closeMenu('login')" style="vertical-align: middle;" *ngIf="!fservice.user">
    <mat-icon svgIcon="login" style="width: 24px; height: 24px;"></mat-icon>
    <span style="display: inline-block; vertical-align: top; height: 24px; margin: 3px 10px;" i18n>login</span>
</p>
<p (click)="signout('logout')" style="vertical-align: middle;" *ngIf="fservice.user">
    <mat-icon svgIcon="logout" style="width: 24px; height: 24px;"></mat-icon>
    <span style="display: inline-block; vertical-align: top; height: 24px; margin: 3px 10px;" i18n>logout</span>
</p>
<mat-divider></mat-divider>
<p (click)="closeMenu('artwork')" style="vertical-align: middle;">
    <mat-icon svgIcon="image-outline" style="width: 24px; height: 24px;"></mat-icon>
    <span style="display: inline-block; vertical-align: top; height: 24px; margin: 3px 10px;" i18n>watch art</span>
</p>
<mat-divider></mat-divider>
<p (click)="closeMenu('user')" style="vertical-align: middle;">
    <mat-icon svgIcon="face-profile" style="width: 24px; height: 24px;"></mat-icon>
    <span style="display: inline-block; vertical-align: top; height: 24px; margin: 3px 10px;" i18n>my page</span>
</p>
<!--
<mat-divider></mat-divider>
<p (click)="closeMenu('meeting')" style="vertical-align: middle;" *ngIf="fservice.user">
    <mat-icon svgIcon="face-profile" style="width: 24px; height: 24px;"></mat-icon>
    <span style="display: inline-block; vertical-align: top; height: 24px; margin: 3px 10px;" i18n>video chat</span>
</p>
-->
<mat-divider></mat-divider>
<p (click)="closeMenu('readme')" style="vertical-align: middle;">
    <mat-icon svgIcon="help-circle-outline" style="width: 24px; height: 24px;"></mat-icon>
    <span style="display: inline-block; vertical-align: top; height: 24px; margin: 3px 10px;" i18n>app's readme</span>
</p>
<mat-divider></mat-divider>
<br>
<div i18n>related links</div>
<br>
<mat-divider></mat-divider>
<p (click)="closeMenu('lieschen')" style="vertical-align: middle;">
    <mat-icon svgIcon="file-multiple-outline" style="width: 24px; height: 24px;"></mat-icon>
    <span style="display: inline-block; vertical-align: top; height: 24px; margin: 3px 10px;" i18n>thoughts about art</span>
</p>
<mat-divider></mat-divider>
<p (click)="closeMenu('vrgallery')" style="vertical-align: middle;">
    <mat-icon svgIcon="file-multiple-outline" style="width: 24px; height: 24px;"></mat-icon>
    <span style="display: inline-block; vertical-align: top; height: 24px; margin: 3px 10px;" i18n>the v-r.gallery<br>webpage</span>
</p>
<mat-divider></mat-divider>
