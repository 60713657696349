import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { FirestoreService } from 'src/app/service/firestore.service';
import { User } from 'src/app/model/user';
import { GalleryService } from 'src/app/service/gallery.service';

@Component({
  selector: 'upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {

  @Input() file: File;
  @Input() mode: string;

  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;
  user: User;

  /**
   *Creates an instance of UploadTaskComponent.
   * @param {AngularFireStorage} storage
   * @param {AngularFirestore} db
   * @param {FirestoreService} fservice
   * @memberof UploadTaskComponent
   */
  constructor(private storage: AngularFireStorage, private db: AngularFirestore,
     private fservice: FirestoreService, private galserv: GalleryService)
  {
    this.user = this.fservice.user;
  }
  /**
   *
   *
   * @memberof UploadTaskComponent
   */
  ngOnInit() {
    this.user = this.fservice.user;
    this.startUpload();
  }
  /**
   *
   *
   * @memberof UploadTaskComponent
   */
  startUpload() {
    // The storage path
    let path = `images/${this.fservice.user.uid}/${this.file.name}`;
    switch (this.mode) {
      case 'profile': path = `profiles/${this.fservice.user.uid}/${this.file.name}`; break;
      case 'background': path = `profiles/${this.fservice.user.uid}/back_${this.file.name}`; break;
    }

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, this.file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot   = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize( async() =>  {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        switch (this.mode) {
          case 'profile':
          this.fservice.user.profileImage = this.downloadURL;
          this.fservice.doUpdateUser(this.fservice.user);
          break;
          case 'background':
             this.fservice.user.profileBackground = this.downloadURL;
             this.fservice.doUpdateUser(this.fservice.user);
             break;
        }
        //profile images do not belong into the images
        if (this.mode != 'profile' && this.mode != 'background') {
          this.db.collection('images').add( { downloadURL: this.downloadURL, path }).then(docRef =>
            {
              // sync the uploaded image url to the v-r.gallery db
              // use that image as a texture of a plain
              const image_uid = docRef.id;
              const date = new Date();
              this.galserv.setGalleryImage(this.fservice.user.uid,
              this.downloadURL, image_uid,
              'artist: '+this.fservice.user.prename+' '+this.fservice.user.surname+', uploaded:'+
              date.getUTCFullYear() + '-' + (date.getUTCMonth()+1) + '-' +date.getUTCDate()).subscribe(
                response => {
                  console.log(response);
                  if (response['error']['number'] != 0) {
                    alert('FAILED: '+response['error']['message']);
                  } else {
                    //set the room_id to the image with docRef.id
                    this.db.collection('images').doc(docRef.id).update({ room_id: response['data']['room_id']});//catch error via then?
                  }
                }
              );
            }
          );

        }
      }),
    );
  }
  /**
   *
   *
   * @param {*} snapshot
   * @returns
   * @memberof UploadTaskComponent
   */
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

}
