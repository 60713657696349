import {Component} from '@angular/core';
import { FirestoreService } from '../../service/firestore.service';
import { FormGroup, FormControl } from '@angular/forms';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
  })
  export class LoginComponent  {

    hide: boolean = true;
    loginForm = new FormGroup({
        email: new FormControl(),
        password: new FormControl()
    });
    /**
     *Creates an instance of LoginComponent.
     * @param {FirestoreService} fservice
     * @memberof LoginComponent
     */
    constructor(public fservice: FirestoreService)
    {

    }
    /**
     *
     *
     * @param {*} value
     * @memberof LoginComponent
     */
    loginWithGoogle(value) {
      //console.log('loginWithGoogle->',value);
      this.fservice.doGoogleLogin(value).then( res => {
        this.fservice.setOrUpdateUser(res);
      });
    }
}
