import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatDividerModule} from '@angular/material/divider';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { LoginComponent } from './component/login/login.component';
import { PasswordComponent } from './component/login/password/password.component';
import { VerificationComponent } from './component/login/verification/verification.component';
import { RegisterComponent } from './component/login/register/register.component';
import { FirestoreService } from './service/firestore.service';
import { HomeComponent } from './component/home/home.component';
import { UserComponent } from './component/user/user.component';
import { AframeSceneComponent } from './component/aframescene/aframescene.component';
import { DropzoneDirective } from './directive/dropzone.directive';
import { UploaderComponent } from './component/uploader/uploader.component';
import { UploadTaskComponent } from './component/uploader/upload-task/upload-task.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { NavgationComponent } from './component/navgation/navgation.component';
import { MarkedOptions, MarkedRenderer, MarkdownModule} from 'ngx-markdown';
import { ReadmeComponent } from './component/readme/readme.component';
import { PaypalComponent } from './component/paypal/paypal.component';
import { RequestComponent } from './component/roles/request/request.component';
import { MembershipComponent } from './component/roles/membership/membership.component';
import { MembersroomComponent } from './component/roles/membersroom/membersroom.component';
import { MeetingComponent } from './component/meetRTC/meeting.component';
import { RemoteRtcComponent } from './component/meetRTC/remoteRTC/remotertc.compenent';
import { ExhibitionComponent } from './component/exhibit/exhibition.component';
import { MatExpansionModule } from '@angular/material/expansion';

//import { AngularFireModule } from '@angular/fire';

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();
  //const sanitizer = DomSanitizer;

  renderer.blockquote = (text: string) => {
    return '<blockquote class="blockquote"><p>' + text
    + '</p></blockquote>';
  };

  renderer.code = (text: string) => {
    return '<code class="code">' + text + '</code>';
  }

  renderer.codespan = (text: string) => {
    return '<code class="codespan">' + text + '</code>';
  }

  //renderer.tablerow = (text: string) => {
  //  return '<tr class="table">' + text + '</tr>';
  //}

  renderer.tablecell = (text: string) => {
    return '<td class="table tablecell">' + text + '</td>';
  }

  renderer.link = (href: string, title: string, text: string) => {
    if (href.indexOf('readme/') >= 0) {
      return '<a href="'+href+'" title="'+(title?title:text)+'" class="mat-stroked-button">'+text+'</a>';
    }
    return '<a href="'+href+'" title="'+(title?title:text)+'">'+text+'</a>';
  }

  //renderer.table = (text: string) => {
    //console.log(text);
    //return '<table class="table">'+text+'</table>';
  //}

  return {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PasswordComponent,
    VerificationComponent,
    RegisterComponent,
    HomeComponent,
    UserComponent,
    AframeSceneComponent,
    DropzoneDirective,
    UploaderComponent,
    UploadTaskComponent,
    NavgationComponent,
    ReadmeComponent,
    PaypalComponent,
    RequestComponent,
    MembershipComponent,
    MembersroomComponent,
    MeetingComponent,
    RemoteRtcComponent,
    ExhibitionComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSidenavModule,
    MatToolbarModule,
    MatDividerModule,
    MatExpansionModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatCardModule,
    MatSlideToggleModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    HttpClientModule,
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory
      },
    }),


  ],
  providers: [
    //DataService,
    FirestoreService,
    AngularFireStorage,
    AngularFireAuthGuard
  ],
  //schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconSet(
        sanitizer.bypassSecurityTrustResourceUrl('assets/mdi.svg'));
  }
}
