import { Component, OnInit } from '@angular/core';
import { FirestoreService } from 'src/app/service/firestore.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {

  mode: string;

  constructor(public fservice: FirestoreService, private route:ActivatedRoute) {

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['mode']) {
        this.mode = params['mode'];
      }
    });
  }

  isHovering: boolean;

  files: File[] = [];
  /**
   *
   *
   * @param {boolean} event
   * @memberof UploaderComponent
   */
  toggleHover(event: boolean) {
    this.isHovering = event;
  }
  /**
   *
   *
   * @param {FileList} files
   * @memberof UploaderComponent
   */
  onDrop(files: FileList) {
    if (!this.fservice.user) return;
    console.log('files->', files);
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));
    }
  }
  /**
   *
   *
   * @param {*} event
   * @memberof UploaderComponent
   */
  onFileSelect(event) {
    if (!this.fservice.user) return;
    console.log('event.target files->', event.target, event.target.files);
    this.onDrop(event.target.files);
  }
}
