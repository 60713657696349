<mat-card>
    <mat-card-content>
        <div>
            <img src="https://instagram.fham6-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s750x750/135497890_672043643476074_6955779978574708624_n.jpg?_nc_ht=instagram.fham6-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=Mrmb4LSjmxQAX_V5Wr8&tp=1&oh=ffb73fcf2d4a4475dbcfd94cd093a870&oe=604A28EC">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            The front window
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <p>
                        <img src="https://instagram.fham6-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s750x750/137237043_230752762015601_1813593530504855504_n.jpg?_nc_ht=instagram.fham6-1.fna.fbcdn.net&_nc_cat=105&_nc_ohc=iy0K6e8XT5QAX-pG8vZ&tp=1&oh=31eaeacef9d547272b3e7ee83bd9034e&oe=6047A157">
                        <br>
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Yellow Framed ZumZum Sculpture
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-panel-description>
                                    <img src="">
                                </mat-panel-description>
                                <dl>
                                    <dd></dd>
                                </dl>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        The Four 354 ZimZum Variations
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-panel-description>
                                    <img src="">
                                </mat-panel-description>
                                <dl>
                                    <dd>one</dd>
                                    <dd>four</dd>
                                    <dd>three</dd>
                                    <dd>two</dd>
                                </dl>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        15 postcard sized watercolour images
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-panel-description>
                                    <img src="">
                                </mat-panel-description>
                                <dl>
                                    <dd> 1. ""</dd>
                                    <dd> 2. ""</dd>
                                    <dd> 3. ""</dd>
                                    <dd> 4. ""</dd>
                                    <dd> 5. "" <b style="color:#fff; background: #900; display: inline-block; padding: 3px 6px; border-radius: 5px; "> sold </b></dd>
                                    <dd> 6. ""</dd>
                                    <dd> 7. ""</dd>
                                    <dd> 8. ""</dd>
                                    <dd> 9. ""</dd>
                                    <dd>10. ""</dd>
                                    <dd>11. ""</dd>
                                    <dd>12. ""</dd>
                                    <dd>13. ""</dd>
                                    <dd>14. ""</dd>
                                    <dd>15. ""</dd>
                                </dl>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            The iterior
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-panel-description></mat-panel-description>
                    <p>
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Left side
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-panel-description>
                                    <img src="">
                                </mat-panel-description>
                                <dl>
                                    <dd>
                                        postcard mobile 7x7 Cube Division
                                    </dd>
                                    <dd>
                                        postcard mobiles The Three Chairities
                                    </dd>
                                    <dd>
                                        electical mobile concept marquette
                                    </dd>
                                    <dd>
                                        9x9 Cdloured Frame Variation
                                    </dd>
                                </dl>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Right side
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-panel-description>
                                    <img src="">
                                </mat-panel-description>
                                <dl>
                                    <dd>
                                        Dyptich Cdloured Frames Mixin
                                    </dd>
                                    <dd>
                                        Dyptich Cdloured Frames Mixin
                                    </dd>
                                    <dd>
                                        ZimZum mobile scdlpture
                                    </dd>
                                    <dd>
                                        ZimZum mobile scdlpture
                                    </dd>
                                    <dd>
                                        ZimZum mobile scdlpture
                                    </dd>
                                </dl>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Back
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-panel-description>
                                    <img src="">
                                </mat-panel-description>
                                <dl>
                                    <dd>
                                        Cdloured Frame Variation
                                    </dd>
                                    <dd>
                                        Cdloured Frame Variation
                                    </dd>
                                    <dd>
                                        Cdloured Frame Variation
                                    </dd>
                                </dl>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Bar
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-panel-description>
                                    <img src="">
                                </mat-panel-description>
                                <p>
                                    6 out of the 56 "..." Small electical Wings mobile
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Front
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <dl>
                                    <dd>
                                        electrical mobile "Firebird"
                                    </dd>
                                    <dd>
                                        electrical mobile "Swanlake"
                                    </dd>
                                </dl>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </p>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </mat-card-content>
</mat-card>
