import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'app-remotertc',
  templateUrl: './remotertc.component.html',
  //styleUrls: ['./meeting.component.css']
})

export class RemoteRtcComponent implements OnInit {
  ngOnInit(): void {

  }

}
