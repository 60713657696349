import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

declare var paypal;
@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss']
})
export class PaypalComponent implements OnInit {
  @ViewChild('paypal', {static: true}) paypalElement: ElementRef;
  constructor() { }
  planId: any;
  subcripId: any;
  //Pass your ClientId + scret key
  //use count malte.kosian-facilator@luckyrats.com
  basicAuth = 'Basic AQlzh0998whOL2OjpCPLM7ecXXAC8RWxHPZ-l-nMnT8-lNl0lTO2SNaMperJW70sRZvwR0Pi6SpdvQzX:'+
              'EH-xSCofNjVdRywmzuOR0hVlaFpFJ8WPewWyjuRBZwIRgDC8zzfUmnMzID5zWRW8JX3mi5wS9BteyRnR';
              /*'QWNWUTBIX05QTVlWMDIzSDhMM3Y2alhNcDRVdaUN2V0M4Mmo4a19hodjdkdS14M3F4dFJ'+
              '6Y2pNTnRPcGN6OUpPdjU1TW9jTllsEV1p5WURWNm46RUZJRWtJd0dYdDFJSTdFRmlEdVQ3UWExV'+
              '2ZXWDZnYmw3Z2w5ajgwZVlsVjI1ODdfUTRHSUxCSWxZfOGg1SzRRZTFhMZU1yVgFZGRThIWXAyRjA=';*/


   /**
   *
   * @memberof PaypalComponent
   */
  ngOnInit() {
    const self = this;
     //Default Plan Id
    this.planId = 'P-6B3047519V3887254L2ATUVY';
    console.log(paypal);
    paypal.Buttons({
      createSubscription: function (data, actions) {
        return actions.subscription.create({
          'plan_id': self.planId,
        });
      },
      onApprove: function (data, actions) {
        console.log(data);
        alert('You have successfully created subscription ' + data.subscriptionID);
        self.getSubcriptionDetails(data.subscriptionID);
      },
      onCancel: function (data) {
        // Show a cancel page, or return to cart
        console.log(data);
      },
      onError: function (err) {
        // Show an error page here, when an error occurs
        console.log(err);
      }

    }).render(this.paypalElement.nativeElement);

  }
  // ============Start Get Subcription Details Method============================
  /**
   *
   *
   * @param {*} subcriptionId
   * @memberof PaypalComponent
   */
  getSubcriptionDetails(subcriptionId) {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        console.log(JSON.parse(this.responseText));
        alert(JSON.stringify(this.responseText));
      }
    };
    xhttp.open('GET', 'https://api.sandbox.paypal.com/v1/billing/subscriptions/' + subcriptionId, true);
    xhttp.setRequestHeader('Authorization', this.basicAuth);

    xhttp.send();
  }
  // ============END Get Subcription Details Method========================

}
