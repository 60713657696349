import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { RegisterComponent } from './component/login/register/register.component';
import { VerificationComponent } from './component/login/verification/verification.component';
import { PasswordComponent } from './component/login/password/password.component';
import { HomeComponent } from './component/home/home.component';
import { AframeSceneComponent } from './component/aframescene/aframescene.component';
import { UploaderComponent } from './component/uploader/uploader.component';
import { UserComponent } from './component/user/user.component';
import { AuthGuard } from "./shared/guard/auth.guard";
import { SecureInnerPagesGuard } from "./shared/guard/secure-inner-pages.guard";
import { ReadmeComponent } from './component/readme/readme.component';
import { MembershipComponent } from './component/roles/membership/membership.component';
import { MembersroomComponent } from './component/roles/membersroom/membersroom.component';
import { RequestComponent } from './component/roles/request/request.component';
import { MeetingComponent } from './component/meetRTC/meeting.component';
import { ExhibitionComponent } from './component/exhibit/exhibition.component';


const routes: Routes = [
  { path: '', component:HomeComponent},
  { path: 'user',   component:UserComponent, canActivate: [AuthGuard] },
  { path: 'home',   redirectTo: '', pathMatch: 'full' },
  { path: 'login', component:LoginComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'register', component:RegisterComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'verification', component:VerificationComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'password', component:PasswordComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'vrscene', component:AframeSceneComponent},
  { path: 'upload', component:UploaderComponent, canActivate: [AuthGuard]},
  { path: 'upload/:mode', component:UploaderComponent, canActivate: [AuthGuard]},
  { path: 'artwork', component:AframeSceneComponent},
  { path: 'readme', component:ReadmeComponent},
  { path: 'exhibition', component:ExhibitionComponent},
  { path: 'readme/:asset', component:ReadmeComponent},
  { path: 'meeting', component: MeetingComponent, canActivate: [AuthGuard]},
  { path: 'membership', component:MembershipComponent, canActivate: [AuthGuard]},
  { path: 'membersroom', component:MembersroomComponent, canActivate: [AuthGuard]},
  { path: 'requestrole', component:RequestComponent, canActivate: [AuthGuard]},
  { path: 'requestrole/:role', component:RequestComponent, canActivate: [AuthGuard]},
  { path: '**',   redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
