import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-readme',
  templateUrl: './readme.component.html',
  styleUrls: ['./readme.component.scss']
})
export class ReadmeComponent implements OnInit{

  @Input() href:string = 'assets/README.md';

  constructor(private route:ActivatedRoute) {

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      console.log(params);
      if (params['asset']) this.href = 'assets/'+params['asset']+'.md';
    });
  }

}
