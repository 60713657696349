<!--mat-card>
    <mat-card-header-->
<p>paypal-component works!</p>
<!--/mat-card-header>
    <mat-card-content-->
<div style="flex-direction: row; display: flex; justify-content: space-between;">
    <p>
        <select id="ddlplan" [(ngModel)]="planId">
        <option value="">Select Plan </option>
        <option value="P-6B3047519V3887254L2ATUVY">One Year Membership</option>
      </select>
    </p>
    <div>
        <div id="paypalElement" #paypal></div>
    </div>
</div>
<!--/mat-card-content>
</mat-card-->