<mat-card>
    <mat-card-header>
        <p i18n>membership works!</p>
    </mat-card-header>
    <mat-card-content>

        <p i18n>test page</p>
        <p i18n>we will notify you when a support membership is available.<br>So long the <a [routerLink]="['/membersroom']">members' room</a> stays open for all.</p>
        <app-paypal></app-paypal>
    </mat-card-content>
</mat-card>
