<mat-card>
    <img src="/assets/artisessential_original.jpg" alt="open call of participation" mat-card-image>
    <mat-card-content>
        <h1 i18n>
            Welcome to the virtual reality gallery.
        </h1>
        <p i18n>Open call of praticipation.</p>
        <p i18n>Test of the Minimal Viable Product MVP for the v-r.gallery.</p>
        <p><span i18n>Please, read the <a [routerLink]="['/readme']">readme</a> first.</span></p>
    </mat-card-content>
</mat-card>
