import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-exhibition',
  templateUrl: './exhibition.component.html',
  styleUrls: ['./exhibition.component.scss']
})
export class ExhibitionComponent implements OnInit {
  /**
   *Creates an instance of AframeSceneComponent.
   * @param {DomSanitizer} domsanitizer
   * @memberof AframeSceneComponent
   */
  constructor(public domsanitizer:DomSanitizer) { }
  /**
   *
   *
   * @memberof AframeSceneComponent
   */
  ngOnInit(): void {
  }

}
