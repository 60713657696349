import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../../service/firestore.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  passwordForm = new FormGroup({
    email: new FormControl()
  });
  /**
   *Creates an instance of PasswordComponent.
   * @param {FirestoreService} fservice
   * @memberof PasswordComponent
   */
  constructor(public fservice: FirestoreService) { }
  /**
   *
   *
   * @memberof PasswordComponent
   */
  ngOnInit(): void {
  }
  /**
   *
   *
   * @param {*} value
   * @memberof PasswordComponent
   */
  resetPassword(value) {
    this.fservice.resetPassword(value);
  }

}
