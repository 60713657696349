export class User {
  email: string;
  uid: string;
  firstLogin: any;
  lastLogin: any;
  prename: string;
  public: boolean;
  surname: string;
  role: string;
  name: string;
  interests: any;
  emailVerified: boolean;
  profileImage: string;
  profileBackground: string;
}
