import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../../service/firestore.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  hide: boolean = true;
  registerForm = new FormGroup({
    email: new FormControl(),
    password: new FormControl(),
    password2: new FormControl()
  });
  /**
   *Creates an instance of RegisterComponent.
   * @param {FirestoreService} fservice
   * @memberof RegisterComponent
   */
  constructor(public fservice: FirestoreService) { }
  /**
   *
   *
   * @memberof RegisterComponent
   */
  ngOnInit(): void {
  }
  /**
   *
   *
   * @param {*} value
   * @memberof RegisterComponent
   */
  tryRegister(value) {
    this.fservice.tryRegister(value);
  }
}
