import {Component, OnInit} from '@angular/core';
import { User } from '../../model/user';
import { FormControl, FormGroup } from '@angular/forms';
//import { DataService } from 'src/app/service/data.service';
import { FirestoreService } from 'src/app/service/firestore.service';
import { Roles } from 'src/app/enum/roles.enum';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
  })
  export class UserComponent implements OnInit {

      user = new User();// :User
      //public = true;
      roles = [];
      userform  = new FormGroup({
        name: new FormControl(),
        prename: new FormControl(),
        surname: new FormControl(),
        interests: new FormControl(),
        role: new FormControl(),
        public: new FormControl()
      });

      constructor( private fservice: FirestoreService, private router: Router) {
        const _roles = Roles;
        this.roles = Object.keys(_roles);

        console.log('roles ->', this.roles, Roles);
      }

      ngOnInit() {
        console.log('roles ->', this.fservice.user);
        this.user = this.fservice.user;
        if (!this.user) return;
        if (typeof this.user.public == 'undefined') this.user.public = true;
        if (typeof this.user.prename == 'undefined') this.user.prename = '';
        if (typeof this.user.surname == 'undefined') this.user.surname = '';
        if (typeof this.user.role == 'undefined') this.user.role = 'VISITOR';
        this.userform.setValue({'name': this.user.prename+' '+this.user.surname,
                                'prename': this.user.prename,
                                'surname': this.user.surname,
                                'role': this.user.role,
                                'interests': this.getInterests(),
                                'public': this.user.public});
      }

      setPublic() {
        this.user.public = !this.user.public;
        console.log('set user public ->', this.user.public);
        this.doUpdateUser();
      }

      setUserRole(ele:any) {
        ele = ele as HTMLFormElement;
        console.log('set user role change ->', ele);
        this.user.role = this.userform.value.role;
        console.log('set user role ->', this.user.role);
        //this.user.role = value;
        this.doUpdateUser();
      }

      setUserName() {
        this.user.name = this.userform.value.name;
        console.log('set user namee ->', this.user.name);
      }

      setUserPreName() {
        this.user.prename = this.userform.value.prename;
        console.log('set user prename ->', this.user.prename);
        this.doUpdateUser();
      }

      setUserSurName() {
        this.user.surname = this.userform.value.surname;
        console.log('set user surname ->', this.user.surname);
        this.doUpdateUser();
      }

      getInterests(): string {
        if (typeof this.user.interests === 'undefined') this.user.interests = new Array<string>();
        return this.user.interests.join(',');
      }

      setInterests() {
        this.user.interests = this.userform.value.interests.split(',');
        console.log('set user interests ->', this.user.interests);
        this.doUpdateUser();
      }

      getArtworkId() {
        return '';//this.datas.getRandomArtwork().id;
      }

      doLogout() {
        this.fservice.doLogout();
      }

      doUpdateUser() {
        this.fservice.doUpdateUser(this.user);
      }

      uploadProfileImage() {
        // goto uploader profile
        this.router.navigateByUrl('/upload/profile');
      }

      uploadProfileBackground() {
        // goto uploader background
        this.router.navigateByUrl('/upload/background');
      }
  }
