import { Component, ViewChild} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { FirestoreService } from './service/firestore.service';
import { User } from './model/user';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vrgallery';
  @ViewChild('sidenav', {'static': false}) sidenav: MatSidenav;
  user: User;
  /**
   *Creates an instance of AppComponent.
   * @param {Router} router
   * @memberof AppComponent
   */
  constructor(private router: Router, public fservice: FirestoreService) {
    this.user = fservice.user;
  }

  /**
   *
   *
   * @param {string} navsection
   * @memberof AppComponent
   */
  closeMenu(navsection: string) {
    this.sidenav.close();

    this.router.navigateByUrl('/'+navsection);
  }
}
