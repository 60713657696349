<mat-card>
    <mat-card-header i18n>
        Email verification
    </mat-card-header>
    <mat-card-content>
        <p i18n>
            We use firebase for the sign in. You will receive an email from noreply@v-r-gallery.firebaseapp.com.
        </p>
        <div class="authBlock">
            <h3 i18n>Thank You for Registering</h3>

            <div class="formGroup" *ngIf="fservice.user">
                <p><span i18n>We have sent a confirmation email to </span><b>{{fservice.user.email}}</b>.</p>
                <p i18n>Please check your email and click on the link to verfiy your email address.</p>
            </div>

            <!-- Calling SendVerificationMail() method using authService Api -->
            <p>
                <button class="mat-raised-button mat-primary" (click)="sendVerificationEmail()" i18n>
              Resend Verification Email
            </button>
            </p>

        </div>

        <p>
            <span routerLink="/login" style="cursor: pointer;" i18n>Go back to log in.</span>
        </p>
    </mat-card-content>
</mat-card>
