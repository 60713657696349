<div>
    <div id="remotes">
        <h4 i18n>devices</h4>
        <app-remotertc *ngFor="let v of views" #remotes></app-remotertc>
    </div>
    <div style="display: inline-block;">
        <h4 i18n>device</h4>
        <video id="device" controls autoplay muted #remote></video>
    </div>
    <div>
        <h4 i18n>me</h4>
        <video id="me" controls autoplay muted #me></video>
    </div>
</div>
<button mat-raised-button color="primary" (click)="showRemote()" [disabled]="callActive" i18n>Connect</button>
<button mat-raised-button color="warn" (click)="hangup()" [disabled]="!callActive" i18n>Disconnect</button>
