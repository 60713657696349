import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FirestoreService } from 'src/app/service/firestore.service';

@Component({
  selector: 'app-navgation',
  templateUrl: './navgation.component.html',
  styleUrls: ['./navgation.component.scss']
})
export class NavgationComponent implements OnInit {

  @Output() navigate = new EventEmitter<any>();

  constructor(public fservice: FirestoreService) { }

  ngOnInit(): void {
  }

  /**
   *
   *
   * @param {string} navsection
   * @memberof AppComponent
   */
  closeMenu(navsection: string) {
    if (navsection == 'lieschen') location.href = 'https://lieschen.art';
    if (navsection == 'vrgallery') location.href = 'https://v-r.gallery';
    this.navigate.emit(navsection);
  }

  signout(navsection: string) {
    this.fservice.doLogout();
    this.navigate.emit('/');
  }
}
