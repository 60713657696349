<!-- Toolbar -->
<mat-toolbar>
    <div style="width: 100%; display: flex; justify-content: space-between; flex-direction: row;">
        <button mat-fab (click)="sidenav.open()" color="primary" style="border: 0; outline: 0;"><mat-icon svgIcon="menu"></mat-icon></button>
        <div style="display: flex; vertical-align: middle;">
            <img width="316" height="316" style="width: 40px; height: 40px;" alt="v-r.gallery Logo" src="https://lieschen.art/img/vrgallery.png" />
            <span style="margin: 5px 10px;">v-r.gallery MVP</span></div>
        <div>
            <a [routerLink]="['/user']" *ngIf="fservice.isLoggedIn">
                <img [src]="fservice.user.profileImage" onError="this.src='assets/profile.jpg'" style="width: 40px; height: 40px; border-radius: 20px; border: solid 0px #fff;">
            </a>
            <a [routerLink]="['/login']" *ngIf="!fservice.isLoggedIn">
                <img *ngIf="!user" src="assets/profile.jpg" style="width: 40px; height: 40px; border-radius: 20px; border: solid 0px #fff;">
            </a>
        </div>
    </div>
</mat-toolbar>
<mat-sidenav-container style="margin: auto; width: 100%; min-height: calc(100vh - 60px); height: auto; background: transparent;" [hasBackdrop]="true" (backdropClick)="closeMenu('backdrop')">

    <mat-sidenav #sidenav [fixedInViewport]="true" Fixed (keydown.escape)="closeMenu('escape')" style="width: 200px; padding: 10px">
        <app-navgation (navigate)="closeMenu($event)"></app-navgation>
    </mat-sidenav>

    <mat-sidenav-content>
        <div class="container">
            <ng-container>
                <router-outlet></router-outlet>
            </ng-container>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
