<mat-card>
    <img [src]="user.profileBackground" alt="user background image" (click)="uploadProfileBackground()" style="height: 30vh; filter: grayscale(30%); opacity: 0.5;" onerror="this.src='assets/profile.jpg'" mat-card-image>
    <div style="position: absolute; top: 2.5vh; left: 7.5%; width: 85%; height: 25vh;">
        <img [src]="user.profileImage" onerror="this.src='assets/profile.jpg'" (click)="uploadProfileImage()" alt="user.name" style="height: 100%;">
        <h1 style="position: absolute; top: 43%; right: 0">{{user.prename}} {{user.surname}}<br>
            <sub style="font-size: 50%">{{user.uid}}</sub></h1>
    </div>
    <mat-card-content>
        <p i18n>Basics and editor of the profil</p>
        <p>
            <!--
            <a *ngIf="user.collection && user.collection.artworks && user.collection.artworks.length > 0" class="mat-raised-button">View your collection</a>
            <span *ngIf="!user.collection || !user.collection.artworks || user.collection.artworks.length == 0">
             Your art collection is empty.
            </span>-->
        </p>
        <p><a [routerLink]="['/artwork/'+getArtworkId()]" class="mat-raised-button" i18n>watch some art and collect</a></p>
        <p i18n>Show your payments, orders etc.</p>
        <br>
        <mat-divider></mat-divider>
        <br>
        <p *ngIf="user.role">
            <!-- && (user.role == 'ARTIST'  || user.role == 'GALLERY' )">-->
            <span i18n>Experimantal access for every one. <b>TODO</b> bind on roles not visitor,member,collector</span><br>
            <a [routerLink]="['/upload']" class="mat-raised-button"><span  i18n>Upload a new image</span>pan>s</a>
        </p>
        <!--mat-form-field-->
        <form [formGroup]="userform">
            <p>
                <mat-label i18n>your prename</mat-label><br>
                <input matInput type="text" formControlName="prename" (change)="setUserPreName()" class="mat-input-element">
                <!--/mat-form-field-->
            </p>
            <p>
                <mat-label i18n>your surname</mat-label><br>
                <input matInput type="text" formControlName="surname" (change)="setUserSurName()" class="mat-input-element">
                <!--/mat-form-field-->
            </p>
            <p style="display: none;">
                <mat-label i18n>your name</mat-label><br>
                <input matInput type="hidden" formControlName="name" (change)="setUserName()" [value]="user.name" class="mat-input-element">
                <!--/mat-form-field-->
            </p>
            <p>
                <mat-label i18n>interests</mat-label><br>
                <input matInput type="text" formControlName="interests" (change)="setInterests()" [value]="getInterests()" class="mat-input-element" placeholder="comma seperated list">
                <!--/mat-form-field-->
            </p><br>
            <!--mat-form-field-->
            <p>
                <mat-label i18n>role/profession</mat-label><br>
                <mat-select formControlName="role" (ngModelChange)="setUserRole(this)">
                    <mat-option class="mat-input-element" i18n>--select something --</mat-option>
                    <mat-option *ngFor="let role of roles" [value]="role" class="mat-input-element">{{role}}</mat-option>
                    <!--mat-option *ngFor="let role of roles" [value]="role" [disabled]="(role == 'ARTIST' || role == 'GALLERY')" class="mat-input-element">{{role}}</mat-option-->
                </mat-select>
            </p>
            <!--/mat-form-field--><br>
            <p>
                <mat-label *ngIf="user.public" i18n>friends</mat-label>
                <mat-label *ngIf="!user.public" i18n>this is not a public profile</mat-label><br>
                <mat-slide-toggle formControlName="public" (ngModelChange)="setPublic()" [(checked)]="user.public" i18n>public profile</mat-slide-toggle>
                <button class="mat-raised-button primary" style="float: right;" (click)="doLogout()" i18n>sign out</button>
            </p>
        </form>
        <br>

    </mat-card-content>
</mat-card>
