import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  private baseUrl = 'https://v-r.gallery/api/gallery.php'

  constructor(private httpc: HttpClient) { }

  setGalleryImage(owner_uid:string, image_url: string, image_uid:string, comments: string) : Observable<any> {
    if (!comments || comments==null || comments.trim() == '') comments='';

    const body = {
      'owner_uid': owner_uid,
      'image_url': image_url,
      'image_uid': image_uid,
      'comments': comments
    };
    return this.httpc.post(this.baseUrl, body);
  }

  getGallerImage(room_id:number, owner_uid:string) : Observable<any> {
    return this.httpc.get(this.baseUrl+'?room_id='+room_id+'&owner_uid='+owner_uid);
  }
}
