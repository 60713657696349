<mat-card>
    <mat-card-header i18n>Register</mat-card-header>
    <mat-card-content>
        <form [formGroup]="registerForm">
            <p>
                <mat-label i18n>Email address</mat-label>
                <input matInput type="email" formControlName="email" class="mat-input-element" placeholder="your email address" required>
            </p>
            <p>
                <mat-label i18n>Password</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" class="mat-input-element" formControlName="password" placeholder="password" required>
                <mat-icon style="position: relative; top: -25px; left: calc(100% - 30px);" (click)="hide = !hide" [svgIcon]="hide ? 'eye-off' : 'eye-outline'"></mat-icon>
            </p>
            <p>
                <mat-label i18n>Repeat password</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" class="mat-input-element" formControlName="password2" placeholder="repeat password" required>
                <mat-icon style="position: relative; top: -25px; left: calc(100% - 30px);" (click)="hide = !hide" [svgIcon]="hide ? 'eye-off' : 'eye-outline'"></mat-icon>
            </p>
            <p>
                <label class="error">{{fservice.errorMessage}}</label>
                <label class="success">{{fservice.successMessage}}</label>
            </p>
            <p style="display: flex; justify-content: space-between; flex-direction: row;">
                <span [routerLink]="['/login']" style="cursor: pointer" i18n>You do already have an account.</span>
            </p>
        </form>


    </mat-card-content>
    <mat-card-actions>
        <button class="mat-raised-button mat-primary" (click)="tryRegister(registerForm.value)" i18n>register</button>
    </mat-card-actions>
</mat-card>
